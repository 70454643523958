export const transmissions = [
  { value: 1, name: "Manuell" },
  { value: 2, name: "Automatik" },
];

export const fuels = [
  { value: 1, name: "Benzin" },
  { value: 2, name: "Diesel" },
  { value: 3, name: "Elektro" },
  { value: 4, name: "Hybrid" },
  { value: 5, name: "Plug-in-Hybrid" },
];

export const types = [
  { value: 1, name: "Cabriolet" },
  { value: 2, name: "Coupé" },
  { value: 3, name: "Kleinstwagen" },
  { value: 4, name: "Kleinwagen" },
  { value: 5, name: "Kombi" },
  { value: 6, name: "Limousine" },
  { value: 7, name: "Sportwagen" },
  { value: 8, name: "SUV" },
  { value: 9, name: "Van" },
];

export const greenFuelIds = [3, 5];

export const categories = [
  { value: 2, name: "Pkw" },
  { value: 3, name: "Nutzfahrzeuge" },
];
